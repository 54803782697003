<template>
  <div class="project_section">
    <ProyectosTabs :proyectos="proyectos" 
    :titulo="this.$t('projects.title')"
    v-if="proyectos" />
    <ProyectoDetails
      :proyecto="proyectoActivo"
      v-if="proyectos && proyectoActivo"
    />
    <b-overlay v-else :show="true" rounded="sm" />
    <Contacto />
  </div>
</template>

<script>
import ProyectosTabs from "@/components/shared/ProyectosTabs/index.vue";
import ProyectoDetails from "@/components/shared/ProyectoDetails/index.vue";
import Contacto from "@/components/shared/Contacto.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ProyectosTabs,
    ProyectoDetails,
    Contacto,
  },
  methods: {
    ...mapActions({
      getProyectos: "proyectos/getProyectos",
    }),
  },
  computed: {
    ...mapGetters({
      proyectos: "proyectos/proyectos",
      proyectoActivo: "proyectos/proyectoActivo",
    }),
  },
  async mounted() {
    await this.getProyectos();
    this.$store.commit("proyectos/SET_PROYECTO_ACTIVO", this.proyectos[0].id);
  },
};
</script>

<style scoped>
@import "../assets/css/proyectos.css";
</style>
